.c-site {
  &__wrapper {
    max-width: $global-max-width;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 rem_calc(5) rem_calc(30) rem_calc(5) $color-grey-lighter-medium;
    background-color: $color-white;
  }

  &__title {
    padding: rem_calc(16 40 12);
    border-bottom: rem_calc(2) solid $color-grey-light-medium;

    @include bp-large('max') {
      padding: rem_calc(16 20 12);
    }

    h2 {
      margin: 0;
      font-family: museo-sans, sans-serif;
      font-weight: bold;

      a {
        color: inherit;
      }
    }
  }
}

.font-custom-block {
  padding-top: 0;
  padding-bottom: 0;

  * {
    font-family: museo-sans, sans-serif;
    font-weight: bold;
  }

  a {
    color: $color-black;
  }

  h1 {
    font-size: rem_calc(40);

    @include bp-large('max') {
      font-size: rem_calc(32);
    }

    @include bp-medium('max') {
      font-size: rem_calc(24);
    }
  }
}
