.search-results {
  .c-breadcrumb {
    display: none;
  }

  h1 {
    display: block;
    margin-bottom: rem_calc(30);
    font-size: rem_calc(40);
    text-align: center;

    @include bp-large('max') {
      font-size: rem_calc(32);
    }

    @include bp-medium('max') {
      font-size: rem_calc(24);
    }
  }

  .c-search-list {
    display: block;
    max-width: rem_calc(550);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-bottom: rem_calc(40);
    list-style-type: none;

    li {
      hr {
        border: none;
        margin-top: rem_calc(15);
        margin-bottom: rem_calc(15);
        border-top: 1px solid $color-grey-light;
      }

      a {
        color: $color-grey-medium;

        &:hover {
          color: $color-link-alt;
        }
      }
    }
  }
}
