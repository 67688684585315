.select2-container .select2-selection--single {
  height: rem_calc(42);
  border: rem-calc(1) solid $color-grey-dark;

  @include bp-large('max') {
    height: rem_calc(38);
  }

  @include bp-medium('max') {
    height: rem_calc(34);
  }

  &.select2--small {
    height: rem_calc(36);
  }

  &.select2--xsmall {
    height: rem_calc(32);
    border: rem-calc(1) solid $color-grey-dark;
  }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: rem_calc(42);
  color: $color-black;

  @include bp-large('max') {
    line-height: rem_calc(38);
  }

  @include bp-medium('max') {
    line-height: rem_calc(24);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: rem_calc(36);

  @include bp-medium('max') {
    line-height: rem_calc(24);
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  font-size: rem_calc(15);
  line-height: rem_calc(32);

  @include bp-medium('max') {
    line-height: rem_calc(22);
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: rem_calc(44);

  @include bp-large('max') {
    height: rem_calc(40);
  }

  @include bp-medium('max') {
    height: rem_calc(38);
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: rem_calc(38);
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: rem_calc(32);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: $color-black transparent transparent transparent;
  border-width: rem_calc(6) rem_calc(5) 0 rem_calc(5);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent $color-black transparent;
  border-width: 0 rem_calc(5) rem_calc(6) rem_calc(5);
}

.select2-dropdown {
  background-color: $color-white;
  border: rem_calc(1) solid $color-grey-dark;

  &.select2--xsmall {
    border-width: rem_calc(1);
  }
}

.select2-container .select2-selection--single .select2-selection__rendered {
  font-size: rem_calc(16);

  @include bp-medium('max') {
    font-size: rem_calc(14);
  }
}

.select2-results__option {
  @include bp-medium('max') {
    font-size: rem_calc(15);
  }

  .select2-results__options.first-as-label &:first-child {
    background-color: $color-theme-light;
    color: $color-white;
  }

  .select2--small & {
    padding-top: rem_calc(6);
    padding-bottom: rem_calc(6);
  }

  .select2--xsmall & {
    padding: rem_calc(5 10);
  }
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: $color-grey-lighter;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-grey;
  color: $color-black;
}
