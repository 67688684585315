$columns: 12;
$page-blocks-width: $global-max-width + rem_calc(50);

.c-blocks {
  &--container {
    max-width: $global-max-width;
  }

  &-main,
  &-sidebar {
    min-height: 1px;
  }

  &__main {
    width: 100%;

    .c-blocks--has-sidebar & {
      @include bp-large {
        width: 65%;
      }
    }
  }

  &__sidebar {
    width: 100%;
    max-width: 100%;
    padding-left: rem_calc(15);
    padding-top: rem_calc(10);

    @include bp-large {
      width: 35%;
      padding-top: rem_calc(40);
      padding-left: rem_calc(40);
    }

    &--left {
      padding-right: rem_calc(15);

      @include bp-large {
        padding-right: rem_calc(20);
      }
    }

    &--right {
      padding-right: rem_calc(0);
      padding-left: rem_calc(0);

      @include bp-large {
        padding-right: rem_calc(40);
        padding-left: rem_calc(20);
      }
    }
  }
}

.c-block {
  width: 100%;

  &__header {
    width: 100%;
  }

  &--clear {
    width: 100%;
  }

  &--container {
    max-width: $global-max-width;

    @include bp-xxlarge {
      max-width: $page-blocks-width;
    }
  }

  &__bg {
    height: 100%;
    width: 100%;

    .c-block--container.c-block--no-sidebar & {
      width: calc(100% - 92px);

      @include bp-large('max') {
        width: calc(100% - 56px);
      }

      @include bp-medium('max') {
        width: calc(100% - 26px);
      }
    }

    .c-blocks--has-sidebar & {
      width: calc(100% - 26px);
    }

    .c-block--container.c-block--no-sidebar.c-block--side-left & {
      width: calc(200% - 54px);
      left: rem_calc(36);
    }

    .c-block--side-right.c-block--no-sidebar & {
      width: 150%;
    }

    @include bp-xlarge('max') {
      &--mobile {
        min-height: rem_calc(450);
      }
    }

    @include bp-medium('max') {
      &--mobile {
        min-height: rem_calc(300);
      }
    }

    @include bp-small('max') {
      &--mobile {
        min-height: rem_calc(250);
      }
    }
  }

  &__side-bg {
    &-image {
      max-height: 100%;

      @include bp-xlarge('max') {
        max-height: rem_calc(500);
      }

      @include bp-medium('max') {
        max-height: rem_calc(400);
      }
    }
  }

  &__inner {
    max-width: $global-max-width;
    min-height: 100px;
    padding: rem_calc(0 5);

    @include bp-custom($global-max-width) {
      @if($global-max-width > ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
      }
    }

    @include bp-xxlarge {
      @if($global-max-width <= ($bp-xxlarge - 1)) {
        max-width: $page-blocks-width;
      }
    }

    &--full {
      padding: rem_calc(0);
    }
  }

  &__content {
    &-inner {
      // .c-block--container & {
      //   padding-left: rem_calc(18);
      //   padding-right: rem_calc(18);

      //   &--padding {
      //     padding: rem_calc(13 35);

      //     @include bp-medium('max') {
      //       padding: rem_calc(13);
      //     }
      //   }
      // }

      .c-block--container & {
        padding: rem_calc(0 28 10);
      }

      &--padding {
        padding: rem_calc(13 38);

        @include bp-large('max') {
          padding: rem_calc(13 20);
        }

        @include bp-medium('max') {
          padding: rem_calc(13 10);
        }
      }
    }

    @include bp-large {
      .c-blocks-has-sidebar--left & {
        padding-right: rem_calc(40);
      }

      .c-blocks-has-sidebar--right & {
        padding-left: rem_calc(40);
      }
    }
  }

  &--w-75 .c-block__inner,
  &__content--w-75 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.75;
    }
  }

  &--w-66 .c-block__inner,
  &__content--w-66 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.66;
      width: 66%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.66;
    }
  }

  &--w-50 {
    width: 50%;
    max-width: 50%;

    @include bp-large('max') {
      width: 100%;
      max-width: 100%;
    }
  }

  &__content--w-50 {
    @include bp-xlarge {
      max-width: $global-max-width * 0.75;
      width: 75%;
    }

    @include bp-xlarge-medium {
      max-width: $global-max-width * 0.5;
      width: 50%;
    }

    @include bp-xxlarge {
      max-width: $page-blocks-width * 0.5;
    }
  }
}

@for $i from 5 through 8 {
  div[class*='c-block--h-fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
    @include bp-xxlarge('max') {
      @if( $i >= 7) {
        min-height: rem_calc(600) !important;
      }
    }

    @include bp-large('max') {
      min-height: rem_calc(500) !important;
    }

    @include bp-medium('max') {
      min-height: rem_calc(400) !important;
    }
  }
}

.owl-nav {
  .icon {
    width: rem_calc(15);
    height: rem_calc(25);
    padding: 0;

    .c-block__columns-arrows--dark &,
    .c-block__slider-arrows--dark & {
      fill: $color-theme;
    }
  }

  .owl-prev,
  .owl-next {
    top: auto;
    transform: translateY(-50%);
  }

  .owl-prev {
    left: rem_calc(15);
  }

  .owl-prev:hover {
    left: rem_calc(10);
  }

  .owl-next {
    right: rem_calc(15);
  }

  .owl-next:hover {
    right: rem_calc(10);
  }
}

.owl-dots {
  .owl-dot {
    width: rem_calc(11);
    height: rem_calc(11);
    margin: 0 rem_calc(6);
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-theme;
      border-color: $color-theme-dark;
    }
  }

  .owl-dot.active {
    width: rem_calc(11);
    height: rem_calc(11);
    background-color: rgba(0, 0, 0, 0.9);
    border-color: $color-theme-dark;

    .c-block__slider-dots--dark &,
    .c-block__gallery-dots--dark &,
    .c-block__columns-dots--dark & {
      background-color: $color-white;
      border: rem_calc(1) solid $color-theme-dark;
    }
  }
}

.c-block__slider {
  padding-bottom: rem_calc(40);

  &--full,
  &--full &-slide {
    height: 100vh;
    min-height: rem_calc(400);

    @include bp-medium {
      min-height: rem_calc(500);
    }

    @include bp-large {
      min-height: rem_calc(600);
    }

    @include bp-xxlarge {
      min-height: rem_calc(700);
    }
  }

  @for $i from 5 through 10 {
    div[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        min-height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        min-height: rem_calc(400) !important;
      }
    }

    .item-video[class*='c-block__slider-slide--fixed-#{$i}'] { /* stylelint-disable-line selector-attribute-quotes */
      @media (max-width: $bp-large) {
        height: rem_calc(500) !important;
      }

      @media (max-width: $bp-medium) {
        height: rem_calc(400) !important;
      }
    }
  }

  &-slide {
    .c-block__slider--auto:not(.c-block__slider--carousel) & {
      min-height: rem_calc(400);

      @include bp-medium {
        min-height: rem_calc(500);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(450);
        }
      }

      @include bp-large {
        min-height: rem_calc(600);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(480);
        }
      }

      @include bp-xxlarge {
        min-height: rem_calc(700);

        .c-blocks--has-sidebar &,
        .c-page__content-shortcode & {
          min-height: rem_calc(500);
        }
      }
    }

    &-caption {
      &-container {
        max-width: $global-max-width;
      }

      &-inner {
        .c-block__slider--full & {
          max-width: $global-max-width - rem_calc(26);
        }
      }

      &--50 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }

        @include bp-large {
          width: 50%;

          .c-blocks--has-sidebar & {
            width: 75%;
          }
        }
      }

      &--75 {
        width: 100%;

        @include bp-medium {
          width: 75%;
        }
      }
    }
  }

  .owl-dots {
    bottom: rem_calc(5);
  }
}

.c-block__columns {
  &-column {
    .c-book__cover {
      width: 35%;
      max-width: rem_calc(170);
    }

    &-title {
      color: $color-black;
      margin-bottom: rem_calc(30);
    }

    &-meta {
      font-size: rem_calc(15);
    }
  }

  &-btn {
    width: 100%;
  }
}

.home-columns {
  .c-block__columns-column {
    &-inner {
      width: rem_calc(195);
      margin-left: auto;
      margin-right: auto;
    }

    &-title {
      color: $color-white;
      margin: 0;
      padding: rem_calc(10 15);
      background-color: $color-black;

      a {
        color: inherit;

        &:hover {
          color: $color-grey-light;
        }
      }
    }

    &-image {
      border-top: 1px solid $color-white;
    }

    @include bp-large {
      &:nth-child(-n+4) {
        padding-top: 25px;

        .c-block__columns-column-image {
          position: relative;
          margin-bottom: rem_calc(25);

          @include bp-xlarge-medium('max') {
            margin-bottom: rem_calc(30);
          }

          &:after {
            position: absolute;
            width: 100%;
            bottom: rem_calc(-25);
            content: '';
            display: block;
            border-bottom: 2px solid $color-grey-medium-light;
          }
        }
      }
    }

    @include bp-large('max') {
      padding-top: 15px !important;

      .c-block__columns-column-image {
        position: relative;
        margin-bottom: rem_calc(25);

        &:after {
          position: absolute;
          width: 100%;
          bottom: rem_calc(-25);
          content: '';
          display: block;
          border-bottom: 2px solid $color-grey-medium-light;
        }
      }

      @include bp-medium {
        &:nth-last-child(-n+2) {
          .c-block__columns-column-image {
            margin-bottom: 0;

            &:after {
              display: none;
            }
          }
        }
      }
    }

    @include bp-medium('max') {
      &:last-child {
        .c-block__columns-column-image {
          margin-bottom: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  .c-block__columns--4 {
    .c-block__columns-column {
      position: relative;

      @include bp-large {
        &:nth-child(4n+1) {
          border-right: 2px solid $color-grey-medium-light;
        }

        &:nth-child(4n+4) {
          border-left: 2px solid $color-grey-medium-light;
        }
      }

      @include bp-large('max') {
        &:nth-child(2n+1) {
          border-right: 2px solid $color-grey-medium-light;
        }
      }

      @include bp-medium('max') {
        &:nth-child(2n+1) {
          border-right: none;
        }
      }
    }
  }
}

.c-block__tabs {
  &-nav {
    li {
      border: rem_calc(1) solid $color-grey-lighter;

      .c-block__tabs--dark & {
        border-color: $color-grey-dark;
      }
    }

    .active {
      a {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }

    a {
      background-color: $color-grey-lighter;

      .c-block__tabs--dark & {
        background-color: $color-grey;
        color: $color-white;
      }

      &:hover {
        background-color: $color-grey-lighter;

        .c-block__tabs--dark & {
          background-color: $color-grey-dark;
          color: $color-white;
        }
      }
    }
  }

  &-content {
    background-color: $color-grey-lighter;
    font-size: rem_calc(14);

    .c-block__tabs--dark & {
      background-color: $color-grey-dark;
      color: $color-white;
    }
  }

  &-accordion {
    &-header {
      a {
        border-bottom: rem_calc(1) solid $color-theme;
        background-color: $color-white;

        .c-block__tabs--dark & {
          background-color: $color-grey;
          color: $color-white;
        }
      }

      .c-arrow-icon {
        fill: $color-grey-dark;

        .c-block__tabs--dark & {
          fill: $color-grey-lighter;
        }
      }

      &.active {
        a {
          border-bottom: 1px solid $color-white;
          background-color: $color-white;

          .c-block__tabs--dark & {
            background-color: $color-grey-dark;
            color: $color-white;
          }
        }
      }
    }

    &-content {
      background-color: $color-grey-super-light;
      font-size: rem_calc(14);

      .c-block__tabs--dark & {
        background-color: $color-grey-dark;
        color: $color-white;
      }
    }
  }
}

.c-block__testimonials {
  max-width: calc(100% - 70px);


  .owl-nav {
    .owl-prev,
    .owl-next {
      background-color: $color-grey-lighter;

      &:hover {
        background-color: $color-grey-light;
      }

      &.invisible,
      &.invisible:hover {
        background-color: $color-grey-lighter;
      }
    }
  }

  &-item {
    background-color: $color-grey-lighter;

    .icon-quote {
      fill: #d6d6d6;
    }
  }

  &-meta {
    img {
      width: rem_calc(60) !important; // to override default owl carousel 100% width
    }
  }

  &-info {
    p {
      font-size: rem_calc(14);
    }
  }
}

.c-block__social-media {
  &-widget {
    &-instafeed {
      a {
        background-color: $color-grey-lighter;
      }

      span {
        color: $color-grey;
        font-size: rem_calc(14);
      }
    }
  }
}

.c-block__newsletters-list {
  &-item-inner {
    max-width: rem_calc(410);
    border: 1px solid $color-grey-lighter;
  }
}

.c-instagram__data {
  background-color: rgba(0, 0, 0, 0.8);

  &-inner {
    max-width: rem_calc(500);
    background-color: $color-white;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
  }
}

.c-block__contact-form {
  .cform-container {
    left: - rem_calc(13);
    width: calc(100% + 26px);
  }
}

.c-block__call-to-action {
  &-content {
    blockquote {
      padding: rem_calc(10 20);
      margin: rem_calc(20 0 25);
      font-size: rem_calc(14);
      border-left: rem_calc(4) solid $color-grey-lighter;
      line-height: 2;
    }
  }
}
