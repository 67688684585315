h1, h2, h3, h4, h5, h6 {
  color: $color-black;
  font-weight: 400;
  line-height: 1.3;

  .c-block__header &,
  .c-block__columns-column-title &,
  .c-footer__column-header & {
    display: inline-block;
    padding-bottom: rem_calc(5);
    color: $color-black;
    border-bottom: rem_calc(4) solid $color-theme-alt;

    .home-columns & {
      color: $color-white;
      padding: 0;
      border: none;
    }
  }
}

h1, h2, h3, h4, h5, h6 {
  strong {
    font-weight: 600;
  }
}

/* stylelint-disable selector-no-qualifying-type */

h1, .h1 {
  font-size: rem_calc(30);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(36);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(28);
  }
}

h2, .h2 {
  font-size: rem_calc(20);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(22);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(18);
  }
}

h3, .h3 {
  font-size: rem_calc(18);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(20);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(16);
  }
}

h4, .h4 {
  font-size: rem_calc(16);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(18);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(15);
  }
}

h5, .h5 {
  font-size: rem_calc(15);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(16);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(14);
  }
}

h6, .h6 {
  font-size: rem_calc(14);

  .u-text--enlarge,
  &.u-text--enlarge {
    font-size: rem_calc(16);
  }

  .u-text--ensmall,
  &.u-text--ensmall {
    font-size: rem_calc(13);
  }
}

@include bp-large('max') {
  h1, .h1 {
    font-size: rem_calc(28);

    .u-text--enlarge,
    &.u-text--enlarge {
      font-size: rem_calc(32);
    }

    .u-text--ensmall,
    &.u-text--ensmall {
      font-size: rem_calc(24);
    }
  }
}
