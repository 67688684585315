.c-main-nav {
  //border-bottom: rem_calc(4) solid $color-grey-light-medium;
  display: flex;
  align-items: center;

  @include bp-large('max') {
    display: none;
  }
}

.c-main-nav__items {
  align-items: center;
  justify-content: flex-start;

  .c-header--static.c-header--image & {
    padding-top: 0;
  }

  a {
    text-decoration: none !important;
  }
}

.c-main-nav__item {
  padding: 0 !important;
  margin: 0;
  margin-right: -3px;

  &:hover {
    > .c-main-nav__dropdown {
      transform: rotate3d(0, 1, 0, 0deg);
    }
  }
}

.c-main-nav__link {
  padding-left: rem-calc(18);
  padding-right: rem-calc(18);
  padding-top: rem_calc(13);
  padding-bottom: rem_calc(13);
  letter-spacing: 1px;

  .c-main-nav__item.menu-item-has-children & {
    padding-right: rem-calc(20);
  }
}

.c-main-nav__dropdown {
  left: auto;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
}

// .c-main-nav__search {
//   margin-left: 15%;

//   @include bp-custom(1700, 'max') {
//     margin-left: 10%;
//   }

//   @include bp-custom(1600, 'max') {
//     margin-left: 5%;
//   }

//   @include bp-xxlarge('max') {
//     margin-left: 0;
//   }
// }

.c-main-nav__search,
.c-main-nav__search-trigger {
  display: none;
}

.c-main-nav__search-icon {
  width: rem-calc(22);
  height: rem-calc(22);
}
