.c-listing__item {
  border-bottom: rem-calc(2) solid $color-grey-light-medium;
}

.c-listing__link {
  color: $color-grey-medium;
  font-size: rem_calc(18);

  @include bp-medium('max') {
    font-size: rem_calc(16);
  }

  &:hover {
    color: $color-grey-medium;
  }
}
