.c-footer {
  max-width: $global-max-width;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border-top: none;

  &__column {
    @include bp-large('max') {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    &-header {
      h3 {
        border-color: $color-theme-alt;
      }
    }
  }

  .c-footer__inner {
    max-width: 366px;
    padding: rem_calc(20 20 20);

    @include bp-large {
      max-width: 100%;
    }
  }

  p {
    line-height: 2rem;
  }
}

.c-footer__copyright {
  background-color: $color-white;
  font-size: rem_calc(14);
  border-top: 1px solid $color-grey-lighter;

  .c-footer__logo {
    max-width: rem_calc(160);
  }
}
