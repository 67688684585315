.c-entry__header {
  background-color: $color-theme;
}

.c-entry__title {
  font-size: rem-calc(20);

  @include bp-medium {
    font-size: rem-calc(18);
  }

  @include bp-large {
    font-size: rem-calc(16);
  }
}

.c-entry__meta {
  font-size: rem-calc(15);
}

.c-entry__meta-item {
  border-left: rem-calc(1) solid $color-black;

  &:first-child {
    border-left: none;
  }
}

.c-entry__author {
  font-size: rem-calc(14);
}

.c-entry__content {
  font-size: rem-calc(14);
}

.c-entry__lead {
  font-size: rem-calc(16);
  line-height: rem-calc(36);
}
