/*
  Project: Press Plus
  Author: Xfive
 */
/* 01. Global settings
   ========================================================================== */
/* 02. Breakpoints
   ========================================================================== */
/* 03. Colors
   ========================================================================== */
body.admin-bar {
  /* stylelint-disable-line selector-no-qualifying-type */
  padding-top: 32px !important;
}

.body__fixed-header {
  padding-top: 7.5rem;
}

@media (max-width: 64rem) {
  .body__fixed-header {
    padding-top: 6.25rem;
  }
}

.body__fixed-header.body__floating-header {
  padding-top: 0;
}

select, textarea, input[type=text], input[type=password],
input[type=datetime], input[type=datetime-local],
input[type=date], input[type=month], input[type=time],
input[type=week], input[type=number], input[type=email],
input[type=url], input[type=tel] {
  height: 1.875rem;
  padding: 0.625rem;
  border: 0.0625rem solid #000;
  color: #000;
  font-size: 0.875rem;
  font-weight: 400;
  background-color: #fff;
}

@media (max-width: 48.0625rem) {
  select, textarea, input[type=text], input[type=password],
  input[type=datetime], input[type=datetime-local],
  input[type=date], input[type=month], input[type=time],
  input[type=week], input[type=number], input[type=email],
  input[type=url], input[type=tel] {
    padding: 0.3125rem 0.625rem;
  }
}

select::placeholder, textarea::placeholder, input[type=text]::placeholder, input[type=password]::placeholder,
input[type=datetime]::placeholder, input[type=datetime-local]::placeholder,
input[type=date]::placeholder, input[type=month]::placeholder, input[type=time]::placeholder,
input[type=week]::placeholder, input[type=number]::placeholder, input[type=email]::placeholder,
input[type=url]::placeholder, input[type=tel]::placeholder {
  color: #666666;
  transition: all 300ms ease-in-out;
}

select:hover, select:focus, textarea:hover, textarea:focus, input[type=text]:hover, input[type=text]:focus, input[type=password]:hover, input[type=password]:focus,
input[type=datetime]:hover,
input[type=datetime]:focus, input[type=datetime-local]:hover, input[type=datetime-local]:focus,
input[type=date]:hover,
input[type=date]:focus, input[type=month]:hover, input[type=month]:focus, input[type=time]:hover, input[type=time]:focus,
input[type=week]:hover,
input[type=week]:focus, input[type=number]:hover, input[type=number]:focus, input[type=email]:hover, input[type=email]:focus,
input[type=url]:hover,
input[type=url]:focus, input[type=tel]:hover, input[type=tel]:focus {
  border: 0.0625rem solid black;
}

label {
  color: #000;
  line-height: 1.6em;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-weight: 400;
  line-height: 1.3;
}

.c-block__header h1,
.c-block__columns-column-title h1,
.c-footer__column-header h1, .c-block__header h2,
.c-block__columns-column-title h2,
.c-footer__column-header h2, .c-block__header h3,
.c-block__columns-column-title h3,
.c-footer__column-header h3, .c-block__header h4,
.c-block__columns-column-title h4,
.c-footer__column-header h4, .c-block__header h5,
.c-block__columns-column-title h5,
.c-footer__column-header h5, .c-block__header h6,
.c-block__columns-column-title h6,
.c-footer__column-header h6 {
  display: inline-block;
  padding-bottom: 0.3125rem;
  color: #000;
  border-bottom: 0.25rem solid #353a40;
}

.home-columns .c-block__header h1, .home-columns
.c-block__columns-column-title h1, .home-columns
.c-footer__column-header h1, .home-columns .c-block__header h2, .home-columns
.c-block__columns-column-title h2, .home-columns
.c-footer__column-header h2, .home-columns .c-block__header h3, .home-columns
.c-block__columns-column-title h3, .home-columns
.c-footer__column-header h3, .home-columns .c-block__header h4, .home-columns
.c-block__columns-column-title h4, .home-columns
.c-footer__column-header h4, .home-columns .c-block__header h5, .home-columns
.c-block__columns-column-title h5, .home-columns
.c-footer__column-header h5, .home-columns .c-block__header h6, .home-columns
.c-block__columns-column-title h6, .home-columns
.c-footer__column-header h6 {
  color: #fff;
  padding: 0;
  border: none;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 600;
}

/* stylelint-disable selector-no-qualifying-type */
h1, .h1 {
  font-size: 1.875rem;
}

h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
  font-size: 2.25rem;
}

h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
  font-size: 1.75rem;
}

h2, .h2 {
  font-size: 1.25rem;
}

h2 .u-text--enlarge, h2.u-text--enlarge, .h2 .u-text--enlarge, .h2.u-text--enlarge {
  font-size: 1.375rem;
}

h2 .u-text--ensmall, h2.u-text--ensmall, .h2 .u-text--ensmall, .h2.u-text--ensmall {
  font-size: 1.125rem;
}

h3, .h3 {
  font-size: 1.125rem;
}

h3 .u-text--enlarge, h3.u-text--enlarge, .h3 .u-text--enlarge, .h3.u-text--enlarge {
  font-size: 1.25rem;
}

h3 .u-text--ensmall, h3.u-text--ensmall, .h3 .u-text--ensmall, .h3.u-text--ensmall {
  font-size: 1rem;
}

h4, .h4 {
  font-size: 1rem;
}

h4 .u-text--enlarge, h4.u-text--enlarge, .h4 .u-text--enlarge, .h4.u-text--enlarge {
  font-size: 1.125rem;
}

h4 .u-text--ensmall, h4.u-text--ensmall, .h4 .u-text--ensmall, .h4.u-text--ensmall {
  font-size: 0.9375rem;
}

h5, .h5 {
  font-size: 0.9375rem;
}

h5 .u-text--enlarge, h5.u-text--enlarge, .h5 .u-text--enlarge, .h5.u-text--enlarge {
  font-size: 1rem;
}

h5 .u-text--ensmall, h5.u-text--ensmall, .h5 .u-text--ensmall, .h5.u-text--ensmall {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

h6 .u-text--enlarge, h6.u-text--enlarge, .h6 .u-text--enlarge, .h6.u-text--enlarge {
  font-size: 1rem;
}

h6 .u-text--ensmall, h6.u-text--ensmall, .h6 .u-text--ensmall, .h6.u-text--ensmall {
  font-size: 0.8125rem;
}

@media (max-width: 64rem) {
  h1, .h1 {
    font-size: 1.75rem;
  }
  h1 .u-text--enlarge, h1.u-text--enlarge, .h1 .u-text--enlarge, .h1.u-text--enlarge {
    font-size: 2rem;
  }
  h1 .u-text--ensmall, h1.u-text--ensmall, .h1 .u-text--ensmall, .h1.u-text--ensmall {
    font-size: 1.5rem;
  }
}

hr {
  border: 0.0625rem solid #ccc;
}

html {
  background: #e8e9ea;
  color: #777777;
  font-size: 1rem;
  line-height: 1.05;
}

a {
  color: #00266b;
  text-decoration: none;
}

a:hover {
  color: #000;
  text-decoration: underline;
}

table:not(.c-events-calendar__table) td {
  font-size: 0.875rem;
}

.c-x5__table {
  background-color: #fff;
}

.c-x5__table--shadow {
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.3);
}

.c-x5__table--border-grey,
.c-x5__table--header-bordered.c-x5__table--border-grey th,
.c-x5__table-rows--bordered.c-x5__table--border-grey td,
.c-x5__table-cols--bordered.c-x5__table--border-grey th,
.c-x5__table-cols--bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-grey td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-grey td {
  border-color: #777777;
}

.c-x5__table--border-black,
.c-x5__table--header-bordered.c-x5__table--border-black th,
.c-x5__table-rows--bordered.c-x5__table--border-black td,
.c-x5__table-cols--bordered.c-x5__table--border-black th,
.c-x5__table-cols--bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered.c-x5__table--border-black td,
.c-x5__table-rows--striped-bordered-reverse.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered.c-x5__table--border-black td,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black th,
.c-x5__table-cols--striped-bordered-reverse.c-x5__table--border-black td {
  border-color: #000;
}

.c-x5__table thead {
  background-color: #000;
  color: #fff;
}

.c-x5__table--header-grey thead {
  background-color: #aaaaaa;
}

.c-x5__table--header-white thead {
  background-color: #fff;
  color: #000;
}

.c-x5__table td {
  font-size: 0.875rem;
}

.c-x5__table-rows--striped tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-bordered tbody tr:nth-child(odd) td,
.c-x5__table-rows--striped-reverse tbody tr:nth-child(even) td,
.c-x5__table-rows--striped-bordered-reverse tbody tr:nth-child(even) td {
  background-color: #eeeeee;
}

.c-x5__table-cols--striped th:nth-child(odd),
.c-x5__table-cols--striped td:nth-child(odd),
.c-x5__table-cols--striped-reverse th:nth-child(even),
.c-x5__table-cols--striped-reverse td:nth-child(even),
.c-x5__table-cols--striped-bordered th:nth-child(odd),
.c-x5__table-cols--striped-bordered td:nth-child(odd),
.c-x5__table-cols--striped-bordered-reverse th:nth-child(even),
.c-x5__table-cols--striped-bordered-reverse td:nth-child(even) {
  background-color: #eeeeee;
}

.c-x5__table--border-grey td,
.c-x5__table--border-grey th {
  border-color: #777777;
}

@media (min-width: 48.0625rem) {
  .c-x5__table--striped tbody tr:nth-child(even),
  .c-x5__table--striped-reverse tbody tr:nth-child(odd),
  .c-x5__table--striped-cols td:nth-child(even),
  .c-x5__table--striped-cols.c-x5__table--header-white th:nth-child(even),
  .c-x5__table--striped-cols-reverse td:nth-child(odd),
  .c-x5__table--striped-cols-reverse.c-x5__table--header-white th:nth-child(odd) {
    background-color: #eeeeee;
  }
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table),
.c-x5__table--editor {
  border: solid 1px #000;
  border-right: none;
  border-bottom: none;
}

table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table) td, table:not(.c-x5__table):not(.c-calendar__table):not(.c-events-calendar__table) th,
.c-x5__table--editor td,
.c-x5__table--editor th {
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

/* ==========================================================================
   #LAYOUT
   ========================================================================== */
.o-layout {
  max-width: 73.125rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.o-layout .o-layout {
  margin-right: -0.78125rem;
  margin-left: -0.78125rem;
}

@media print, screen and (min-width: 48.0625em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 64em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

@media print, screen and (min-width: 72em) {
  .o-layout .o-layout {
    margin-right: -0.78125rem;
    margin-left: -0.78125rem;
  }
}

.o-layout:not(.is-expanded) .o-layout {
  max-width: none;
}

.o-wrapper {
  max-width: 73.125rem;
}

.addtocalendar {
  max-width: 21.875rem;
  width: 100%;
}

.addtocalendar:hover .atcb-link {
  color: #00266b;
}

.atcb-list {
  background-color: #00266b;
  border-radius: 0 0 0.1875rem 0.1875rem;
  box-shadow: 0 0.3125rem 1.25rem #b0b1b3;
}

.atcb-link {
  color: #fff;
  border-radius: 0.1875rem;
  border: 1px solid #00266b;
}

.atcb-link:focus, .atcb-link:hover {
  color: #00266b;
}

.atcb-item-link {
  color: #fff;
}

.atcb-item-link:hover {
  color: #fff;
}

.c-book a:hover {
  color: #353a40;
}

.c-book__inner {
  height: 100%;
  border: 1px solid #eeeeee;
}

@media (max-width: 27.5rem) {
  .c-book__inner {
    max-width: 21.875rem;
  }
}

.c-book__cover {
  width: 50%;
  max-width: 11.875rem;
}

@media (max-width: 27.5rem) {
  .c-book__cover {
    width: 100%;
  }
}

.c-book__single .c-book__cover {
  width: 100%;
  max-width: 21.875rem;
}

.c-book__title {
  color: #666666;
}

.c-book__authors h6 {
  color: #aaaaaa;
}

.c-book__authors a {
  color: #777777;
}

.c-book__publisher,
.c-book__isbn {
  font-size: 0.9375rem;
}

.c-books__orderby label {
  color: #777777;
}

.c-breadcrumb {
  margin-top: 0.9375rem;
  margin-bottom: 0;
  padding: 0 0.3125rem;
}

.c-breadcrumb ul {
  margin-bottom: 0.625rem;
}

.c-breadcrumb__link,
.c-breadcrumb__item a {
  color: #666666;
  font-size: 0.875rem;
}

.c-breadcrumb__link:hover,
.c-breadcrumb__item a:hover {
  color: #666666;
}

.c-btn {
  font-size: 1.125rem;
  font-weight: 400;
  padding: 0.625rem 2.5rem;
  border: 1px solid #00266b;
}

.c-btn .c-icon {
  width: 1.625rem;
  height: 1.125rem;
}

@media (max-width: 85.4375rem) {
  .c-btn {
    padding: 0.5rem 2.1875rem;
    font-size: 1.25rem;
  }
}

@media (max-width: 72rem) {
  .c-btn {
    padding: 0.375rem 1.875rem;
    font-size: 1rem;
    border-width: 1px;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn {
    padding: 0.375rem 1.5625rem;
    font-size: 0.9375rem;
  }
}

.c-btn--small {
  padding: 0.375rem 1.5625rem;
  font-size: 1rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--small {
    padding: 0.3125rem 1.25rem;
    font-size: 0.875rem;
  }
}

.c-btn--small .c-icon {
  width: 1.375rem;
  height: 0.875rem;
}

.c-btn--medium {
  padding: 0.8125rem 3.4375rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--medium {
    padding: 0.6875rem 3.125rem;
  }
}

@media (max-width: 72rem) {
  .c-btn--medium {
    padding: 0.5625rem 2.8125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn--medium {
    padding: 0.4375rem 2.5rem;
  }
}

.c-btn--medium .c-icon {
  width: 1.875rem;
  height: 1.375rem;
}

.c-btn--large {
  padding: 1.125rem 4.375rem;
}

@media (max-width: 85.4375rem) {
  .c-btn--large {
    padding: 0.9375rem 4.0625rem;
  }
}

@media (max-width: 72rem) {
  .c-btn--large {
    padding: 0.8125rem 3.75rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-btn--large {
    padding: 0.625rem 3.125rem;
  }
}

.c-btn--large .c-icon {
  width: 2.125rem;
  height: 1.5625rem;
}

.c-btn--primary {
  background-color: transparent;
  color: #353a40;
  border: 0.125rem solid #353a40;
}

.c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  background-color: #353a40;
  color: #fff;
  border: 0.125rem solid #353a40;
}

.c-btn--primary-filled {
  background-color: #353a40;
  color: #fff;
  border: 0.125rem solid #353a40;
}

.c-btn--primary-filled:hover, .c-btn--primary-filled:active, .c-btn--primary-filled:focus {
  background-color: transparent;
  color: #353a40;
  border: 0.125rem solid #353a40;
}

.c-btn--secondary {
  background-color: transparent;
  color: #000;
  border: 0.125rem solid #000;
}

.c-btn--secondary:hover, .c-btn--secondary:active, .c-btn--secondary:focus {
  background-color: #000;
  color: #fff;
  border: 0.125rem solid #000;
}

.c-btn--secondary-filled {
  background-color: #000;
  color: #fff;
  border: 0.125rem solid #000;
}

.c-btn--secondary-filled:hover, .c-btn--secondary-filled:active, .c-btn--secondary-filled:focus {
  background-color: transparent;
  color: #000;
  border: 0.125rem solid #000;
}

.c-btn--link {
  background-color: transparent;
  color: #00266b;
  border: 0.125rem solid #00266b;
}

.c-btn--link:hover, .c-btn--link:active, .c-btn--link:focus {
  background-color: #00266b;
  color: #fff;
  border: 0.125rem solid #00266b;
}

.c-btn--link-filled {
  background-color: #00266b;
  color: #fff;
  border: 0.125rem solid #00266b;
}

.c-btn--link-filled:hover, .c-btn--link-filled:active, .c-btn--link-filled:focus {
  background-color: transparent;
  color: #00266b;
  border: 0.125rem solid #00266b;
}

.c-btn--link-underline {
  background-color: transparent;
  color: #666666;
  border: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 0.125rem solid #353a40;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  background-color: transparent;
  color: #353a40;
  border: none;
}

.c-btn--link-underline:hover, .c-btn--link-underline:active, .c-btn--link-underline:focus {
  border-bottom: 0.125rem solid #353a40;
}

.c-btn--primary-light {
  background-color: #58606a;
  color: #fff;
  border: 0.125rem solid #58606a;
}

.c-btn--primary-light:hover, .c-btn--primary-light:active, .c-btn--primary-light:focus {
  background-color: #353a40;
  color: #fff;
  border: 0.125rem solid #353a40;
}

.c-btn--secondary-light {
  background-color: #262626;
  color: #fff;
  border: 0.125rem solid #262626;
}

.c-btn--secondary-light:hover, .c-btn--secondary-light:active, .c-btn--secondary-light:focus {
  background-color: #000;
  color: #fff;
  border: 0.125rem solid #000;
}

.c-btn--arrow-primary,
.c-btn--arrow-secondary {
  padding: 0.1875rem 0.625rem;
  border: none;
}

.c-btn--arrow-primary {
  color: #666666;
}

.c-btn--arrow-primary .c-icon {
  fill: #353a40;
}

.c-btn--arrow-primary:hover, .c-btn--arrow-primary:active, .c-btn--arrow-primary:focus {
  color: #353a40;
}

.c-btn--arrow-secondary {
  color: #666666;
}

.c-btn--arrow-secondary .c-icon {
  fill: #000;
}

.c-btn--arrow-secondary:hover, .c-btn--arrow-secondary:active, .c-btn--arrow-secondary:focus {
  color: #000;
}

.c-calendar__pager {
  background-color: #ccc;
  border-radius: 0.1875rem;
  height: 1.875rem;
}

.c-calendar__pager-prev,
.c-calendar__pager-next {
  background-color: #fff;
  border-radius: 0 0.1875rem 0.1875rem 0;
  border: 1px solid #b0b1b3;
  height: 2.25rem;
}

.c-calendar__pager-prev:hover,
.c-calendar__pager-next:hover {
  border-color: #dadada;
}

.c-calendar__pager-prev {
  border-radius: 0.1875rem 0 0 0.1875rem;
}

.c-calendar__pager-link {
  font-size: 0.875rem;
  line-height: 2.25rem;
  color: #464646;
}

.c-calendar__pager-link:hover {
  color: #464646;
}

.c-calendar__pager-link--inactive {
  color: #ccc;
}

.c-calendar__pager-link--inactive:hover {
  color: #ccc;
}

.c-calendar__header {
  background-color: #eeeeee;
  color: #dadada;
  border: 1px solid #b0b1b3;
}

.c-calendar__date-box,
.c-calendar__single-day {
  width: 14%;
  border: 1px solid #b0b1b3;
  color: #dadada;
}

.c-calendar__date-box--past,
.c-calendar__date-box--future,
.c-calendar__single-day--past,
.c-calendar__single-day--future {
  background-color: #f4f4f4;
  color: #aaaaaa;
}

.c-calendar__date-box--today {
  border: 0.125rem solid #000;
  border-bottom-width: 0;
}

.c-calendar__single-day--today {
  border: 0.125rem solid #000;
  border-top-width: 0;
}

.c-calendar__single-day .c-calendar__inner {
  height: 9.375rem;
  line-height: 2.75rem;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner {
    height: 7.5rem;
  }
}

.c-calendar__single-day .c-calendar__inner--full {
  height: 12.8125rem;
  font-size: 0.8125rem;
}

@media (max-width: 80rem) {
  .c-calendar__single-day .c-calendar__inner--full {
    height: 10rem;
  }
}

.c-calendar__event-title a:hover {
  color: #000;
}

.c-calendar__list-item {
  border-bottom: 1px solid #b0b1b3;
}

.c-calendar__list-item--today {
  background-color: #aaaaaa;
}

.c-card {
  box-shadow: 0 0 0 1px #b0b1b3 inset;
}

.c-newsletters-page .c-card {
  max-width: 25.625rem;
}

.c-card--primary {
  box-shadow: 0 0 0 4px #000 inset;
}

.c-courses__header {
  font-size: 1rem;
}

.c-courses__meta {
  border-bottom: 0.0625rem solid #dadada;
  color: #dadada;
}

.c-courses__teaser {
  color: #464646;
}

.c-courses__filter {
  border: 0.125rem solid #ccc;
  border-left-width: 0;
  border-right-width: 0;
}

.c-courses__filter-reset {
  color: #666666;
}

.c-courses__filter-reset:hover {
  color: #666666;
}

.c-courses__item {
  border-bottom: 0.125rem solid #ccc;
}

.flatpickr-prev-month:hover svg, .flatpickr-next-month:hover svg {
  fill: #000 !important;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #eeeeee;
  border-color: #eeeeee;
}

.flatpickr-day.today {
  border-color: #000 !important;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #000 !important;
  background: #000 !important;
  color: #fff !important;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #000;
  border-color: #000;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #000;
}

.flatpickr-day.startRange, .flatpickr-day.prevMonthDay.startRange, .flatpickr-day.nextMonthDay.startRange, .flatpickr-day.endRange, .flatpickr-day.prevMonthDay.endRange, .flatpickr-day.nextMonthDay.endRange {
  border-color: #000;
}

.flatpickr-time input {
  border: solid 0.0625rem #eeeeee !important;
}

.c-entry__header {
  background-color: #000;
}

.c-entry__title {
  font-size: 1.25rem;
}

@media (min-width: 48.0625rem) {
  .c-entry__title {
    font-size: 1.125rem;
  }
}

@media (min-width: 64rem) {
  .c-entry__title {
    font-size: 1rem;
  }
}

.c-entry__meta {
  font-size: 0.9375rem;
}

.c-entry__meta-item {
  border-left: 0.0625rem solid #000;
}

.c-entry__meta-item:first-child {
  border-left: none;
}

.c-entry__author {
  font-size: 0.875rem;
}

.c-entry__content {
  font-size: 0.875rem;
}

.c-entry__lead {
  font-size: 1rem;
  line-height: 2.25rem;
}

.c-events-calendar {
  width: 100%;
}

@media (min-width: 64rem) {
  .c-events-calendar--size-75 {
    width: 75%;
  }
}

@media (min-width: 48.0625rem) {
  .c-events-calendar--size-66 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-events-calendar--size-66 {
    width: 66%;
  }
}

@media (min-width: 48.0625rem) {
  .c-events-calendar--size-50 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-events-calendar--size-50 {
    width: 66%;
  }
}

@media (min-width: 72rem) {
  .c-events-calendar--size-50 {
    width: 50%;
  }
}

.c-events-calendar .fc-header-toolbar h2 {
  top: 0.1875rem;
  font-size: 1.5rem;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    top: 0.3125rem;
    font-size: 1.125rem;
  }
}

@media (max-width: 64rem) {
  .c-events-calendar .fc-header-toolbar h2 {
    font-size: 1rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button {
  background-color: #000;
  color: #fff;
}

@media (max-width: 72rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 0.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-header-toolbar .fc-button {
    font-size: 0.8125rem;
  }
}

.c-events-calendar .fc-header-toolbar .fc-button:hover {
  background-color: #4c535c;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled,
.c-events-calendar .fc-header-toolbar .fc-today-button {
  background-color: #353a40;
}

.c-events-calendar .fc-header-toolbar .fc-state-disabled:hover,
.c-events-calendar .fc-header-toolbar .fc-today-button:hover {
  background-color: #58606a;
}

@media (max-width: 48.0625rem) {
  .c-events-calendar .fc-day-header {
    font-size: 0.8125rem;
  }
}

.c-events-calendar td.fc-today {
  /* stylelint-disable-line selector-no-qualifying-type */
  background-color: #eeeeee;
}

.c-events-calendar .fc-event-container .fc-event {
  padding: 0.0625rem 0.3125rem;
  background-color: #353a40;
  transition: all 300ms ease-in-out;
}

@media (max-width: 27.5rem) {
  .c-events-calendar .fc-event-container .fc-event {
    padding: 0.0625rem 0.125rem;
  }
}

.c-events-calendar .fc-event-container .fc-event:hover {
  background-color: #58606a;
}

.c-events-calendar .fc-event-container .fc-title {
  display: block;
  min-height: 1.125rem;
}

.c-footer {
  max-width: 73.125rem;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  border-top: none;
}

@media (max-width: 64rem) {
  .c-footer__column {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.c-footer__column-header h3 {
  border-color: #353a40;
}

.c-footer .c-footer__inner {
  max-width: 366px;
  padding: 1.25rem 1.25rem 1.25rem;
}

@media (min-width: 64rem) {
  .c-footer .c-footer__inner {
    max-width: 100%;
  }
}

.c-footer p {
  line-height: 2rem;
}

.c-footer__copyright {
  background-color: #fff;
  font-size: 0.875rem;
  border-top: 1px solid #eeeeee;
}

.c-footer__copyright .c-footer__logo {
  max-width: 10rem;
}

.c-form-group label span,
.c-form-group legend span {
  color: #cc0000;
}

.c-form-group .c-field__label {
  font-size: 0.875rem;
  font-weight: 400;
}

.c-form-group .c-field__label .c-icon {
  stroke: #000;
  fill: #000;
}

.c-form-group legend {
  color: #000;
}

.c-form__control.c-form__textarea {
  height: 12.5rem !important;
}

.c-field__error {
  font-size: 0.875rem;
  color: #cc0000;
}

@media (max-width: 48.0625rem) {
  .c-field__error {
    font-size: 0.8125rem;
  }
}

.c-field__message {
  font-size: 0.9375rem;
}

.c-field__message.show {
  width: 80%;
  color: #fff;
}

.c-field__message.error {
  background-color: #cc0000;
}

.c-field__message.success {
  background-color: #1cca98;
}

@media (max-width: 72rem) {
  .c-field__message {
    font-size: 0.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-field__message {
    font-size: 0.875rem;
  }
}

.c-newsletter__form .o-layout {
  width: calc(100% + 26px);
}

.c-newsletter__form .c-form__input,
.c-newsletter__form .c-btn {
  width: 100%;
  height: 4.5rem;
  font-weight: 500;
}

@media (max-width: 85.4375rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 4.125rem;
  }
}

@media (max-width: 64rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 3.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-form__input,
  .c-newsletter__form .c-btn {
    height: 3.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-newsletter__form .c-btn {
    font-size: 0.875rem;
  }
}

.c-newsletter__form .c-form__input {
  border-color: #fff;
  color: #666666;
}

.c-newsletter__form .c-form__input:hover, .c-newsletter__form .c-form__input:focus {
  border-color: #353a40;
}

.c-newsletter__form .c-form__input::placeholder {
  color: #666666;
}

.c-galleries--list {
  padding-top: 0.625rem;
}

@media (max-width: 64rem) {
  .c-galleries--list {
    padding-top: 0;
  }
}

.c-galleries--list li {
  border-bottom: 1px solid #eeeeee;
}

.c-gallery__title {
  font-size: 1.125rem;
}

@media (max-width: 64rem) {
  .c-gallery__title {
    font-size: 1rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-gallery__title {
    font-size: 0.875rem;
  }
}

.c-gallery__title a:hover {
  color: #00266b;
}

.c-galleries--list .c-gallery__title {
  font-size: 1rem;
}

.c-gallery__single-item-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.c-gallery__single-item .icon {
  width: 1.875rem;
  height: 1.875rem;
  fill: #fff;
}

.c-gallery__single--cols-1 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__single--cols-2 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__single--cols-3 .c-gallery__single-item .icon {
  width: 2.5rem;
  height: 2.5rem;
}

.c-gallery__entry {
  padding-top: 1.25rem;
  padding-bottom: 0.625rem;
}

.c-galleries--thumbs .c-gallery__inner {
  max-width: 33.125rem;
  margin-bottom: 1.25rem;
  border: solid 1px #eeeeee;
}

.c-header {
  transition: padding 0.3s ease-in-out, border 0.3s ease-in-out;
}

.c-header--floating {
  padding: 0.625rem 3.75rem;
}

@media (max-width: 93.75rem) {
  .c-header--floating {
    padding: 0.625rem 0.625rem;
  }
}

@media (max-width: 64rem) {
  .c-header--floating-nomobile {
    padding: 0;
  }
}

.c-header__container {
  padding: 0;
}

.c-header--shrink {
  padding: 0;
}

.c-header__inner {
  width: 100%;
  height: auto;
  padding: 0;
  border-bottom: 0.25rem solid #ccc;
}

@media (max-width: 64rem) {
  .c-header__inner {
    height: 3.125rem;
    border-bottom: 0.25rem solid #ccc;
  }
}

.c-header--shrink .c-header__inner {
  height: auto;
}

.c-header--container .c-header__inner {
  max-width: 73.125rem;
}

.c-header--floating .c-header__inner {
  height: auto;
}

.c-header--floating:not(.c-header--full) .c-header__container {
  max-width: 73.125rem;
}

.body__fixed-header--floating .c-header--floating:not(.c-header--full) .c-header__container {
  max-width: none;
}

.c-header--floating.c-header--shrink .c-header__container {
  max-width: none;
}

.c-header--static.c-header--image .c-header__inner {
  align-items: flex-end;
  height: 7.5rem;
}

@media (max-width: 85.4375rem) {
  .c-header--static.c-header--image .c-header__inner {
    height: 6.25rem;
  }
}

@media (max-width: 64rem) {
  .c-header--static.c-header--image .c-header__inner {
    align-items: center;
    height: 3.125rem;
  }
}

.c-header__logo {
  width: 25%;
  padding: 0 0.625rem;
}

.c-header__logo a {
  height: 100%;
  width: 100%;
  max-width: 18.75rem;
}

.c-header__logo h1 {
  color: #fff;
}

@media (max-width: 85.4375rem) {
  .c-header__logo {
    width: 20%;
  }
}

@media (max-width: 80rem) {
  .c-header__logo {
    width: 17%;
  }
}

@media (max-width: 64rem) {
  .c-header__logo {
    max-width: 11.875rem;
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;
}

@media (max-width: 85.4375rem) {
  .c-header__nav {
    width: 80%;
  }
}

@media (max-width: 80rem) {
  .c-header__nav {
    width: 83%;
  }
}

@media (max-width: 64rem) {
  .c-header__nav {
    width: auto;
  }
}

.c-header--static.c-header--image .c-header__mobile-actions {
  margin-top: 0;
}

.c-search {
  background-color: rgba(119, 119, 119, 0.95);
}

.c-search__form {
  max-width: 56.25rem;
  width: 95%;
  border: 1.25rem solid #353a40;
}

@media (max-width: 64rem) {
  .c-search__form {
    border-width: 0.625rem;
    max-width: 31.25rem;
  }
}

.c-search__form:hover {
  border-color: #353a40 !important;
}

.c-search [type=text].c-search__input {
  height: 3.125rem;
}

.c-search__btn {
  height: 3.125rem;
}

.c-search__close {
  color: #353a40;
}

.c-search__close:hover {
  color: #fff !important;
  text-decoration: none !important;
}

.c-search__box {
  display: flex;
  margin-right: 1.25rem;
  width: 12.5rem;
  border: 1px solid #fff;
}

.c-mobile-nav .c-search__box {
  margin: 1.25rem auto 0;
}

.c-search__box-input {
  font-size: 0.75rem !important;
  height: 1.5625rem !important;
  padding: 0.4375rem 0.625rem 0.3125rem 0.625rem !important;
  margin-bottom: 0 !important;
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
  text-transform: uppercase;
}

.c-search__box-input::placeholder {
  color: #aaaaaa !important;
}

.c-search__box-btn {
  height: 1.5625rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.c-search__box svg {
  width: 15px;
  height: 22px;
}

.c-heading {
  border-bottom: none;
}

.c-heading--secondary {
  color: #000;
}

.c-heading--big {
  font-size: 1.125rem;
}

@media (min-width: 48.0625rem) {
  .c-heading--big {
    font-size: 1rem;
  }
}

.c-page__title {
  padding-bottom: 0.3125rem;
  margin-bottom: 0.3125rem;
  margin-left: 0.3125rem;
  font-size: 1.5rem;
  color: #000;
}

.page-template-newsletter-list .c-page__title {
  margin-bottom: 0.9375rem;
}

@media (max-width: 64rem) {
  .c-page__title {
    font-size: 1.125rem;
  }
}

.c-hero--imageless {
  background-color: #ccc;
}

.c-hero__meta {
  font-size: 0.875rem;
}

/* stylelint-disable selector-max-id */
#baguetteBox-overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

#baguetteBox-overlay .full-image figcaption {
  color: #eee;
}

#baguetteBox-overlay .baguetteBox-button {
  background-color: rgba(255, 255, 255, 0) !important;
}

#baguetteBox-overlay .baguetteBox-button svg polyline,
#baguetteBox-overlay .baguetteBox-button svg g {
  stroke: #000 !important;
}

/* stylelint-enable */
.c-link {
  border-bottom: 0.25rem solid #aaaaaa;
  color: #000;
}

.c-link:hover {
  color: #353a40;
  border-color: #353a40;
}

.c-listing__item {
  border-bottom: 0.125rem solid #ccc;
}

.c-listing__link {
  color: #464646;
  font-size: 1.125rem;
}

@media (max-width: 48.0625rem) {
  .c-listing__link {
    font-size: 1rem;
  }
}

.c-listing__link:hover {
  color: #464646;
}

.c-main-nav {
  display: flex;
  align-items: center;
}

@media (max-width: 64rem) {
  .c-main-nav {
    display: none;
  }
}

.c-main-nav__items {
  align-items: center;
  justify-content: flex-start;
}

.c-header--static.c-header--image .c-main-nav__items {
  padding-top: 0;
}

.c-main-nav__items a {
  text-decoration: none !important;
}

.c-main-nav__item {
  padding: 0 !important;
  margin: 0;
  margin-right: -3px;
}

.c-main-nav__item:hover > .c-main-nav__dropdown {
  transform: rotate3d(0, 1, 0, 0deg);
}

.c-main-nav__link {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  letter-spacing: 1px;
}

.c-main-nav__item.menu-item-has-children .c-main-nav__link {
  padding-right: 1.25rem;
}

.c-main-nav__dropdown {
  left: auto;
  transform: rotate3d(1, 0, 0, 90deg) perspective(200px);
}

.c-main-nav__search,
.c-main-nav__search-trigger {
  display: none;
}

.c-main-nav__search-icon {
  width: 1.375rem;
  height: 1.375rem;
}

.c-member__inner {
  border-bottom: 0.25rem solid #ccc;
}

.c-member__title {
  background-color: #ccc;
  font-size: 1rem;
}

.c-member__link {
  color: #000;
}

.c-member__link:hover {
  color: #000;
}

.c-main-nav__trigger {
  width: 1.875rem;
}

.c-main-nav__trigger::after, .c-main-nav__trigger::before {
  border-top-width: 0.25rem;
}

.c-mobile-nav__trigger {
  margin-right: 0.625rem;
}

.c-blocks--container {
  max-width: 73.125rem;
}

.c-blocks-main, .c-blocks-sidebar {
  min-height: 1px;
}

.c-blocks__main {
  width: 100%;
}

@media (min-width: 64rem) {
  .c-blocks--has-sidebar .c-blocks__main {
    width: 65%;
  }
}

.c-blocks__sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0.9375rem;
  padding-top: 0.625rem;
}

@media (min-width: 64rem) {
  .c-blocks__sidebar {
    width: 35%;
    padding-top: 2.5rem;
    padding-left: 2.5rem;
  }
}

.c-blocks__sidebar--left {
  padding-right: 0.9375rem;
}

@media (min-width: 64rem) {
  .c-blocks__sidebar--left {
    padding-right: 1.25rem;
  }
}

.c-blocks__sidebar--right {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 64rem) {
  .c-blocks__sidebar--right {
    padding-right: 2.5rem;
    padding-left: 1.25rem;
  }
}

.c-block {
  width: 100%;
}

.c-block__header {
  width: 100%;
}

.c-block--clear {
  width: 100%;
}

.c-block--container {
  max-width: 73.125rem;
}

@media (min-width: 85.4375rem) {
  .c-block--container {
    max-width: 76.25rem;
  }
}

.c-block__bg {
  height: 100%;
  width: 100%;
}

.c-block--container.c-block--no-sidebar .c-block__bg {
  width: calc(100% - 92px);
}

@media (max-width: 64rem) {
  .c-block--container.c-block--no-sidebar .c-block__bg {
    width: calc(100% - 56px);
  }
}

@media (max-width: 48.0625rem) {
  .c-block--container.c-block--no-sidebar .c-block__bg {
    width: calc(100% - 26px);
  }
}

.c-blocks--has-sidebar .c-block__bg {
  width: calc(100% - 26px);
}

.c-block--container.c-block--no-sidebar.c-block--side-left .c-block__bg {
  width: calc(200% - 54px);
  left: 2.25rem;
}

.c-block--side-right.c-block--no-sidebar .c-block__bg {
  width: 150%;
}

@media (max-width: 72rem) {
  .c-block__bg--mobile {
    min-height: 28.125rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__bg--mobile {
    min-height: 18.75rem;
  }
}

@media (max-width: 27.5rem) {
  .c-block__bg--mobile {
    min-height: 15.625rem;
  }
}

.c-block__side-bg-image {
  max-height: 100%;
}

@media (max-width: 72rem) {
  .c-block__side-bg-image {
    max-height: 31.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__side-bg-image {
    max-height: 25rem;
  }
}

.c-block__inner {
  max-width: 73.125rem;
  min-height: 100px;
  padding: 0 0.3125rem;
}

@media (min-width: 85.4375rem) {
  .c-block__inner {
    max-width: 76.25rem;
  }
}

.c-block__inner--full {
  padding: 0;
}

.c-block--container .c-block__content-inner {
  padding: 0 1.75rem 0.625rem;
}

.c-block__content-inner--padding {
  padding: 0.8125rem 2.375rem;
}

@media (max-width: 64rem) {
  .c-block__content-inner--padding {
    padding: 0.8125rem 1.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__content-inner--padding {
    padding: 0.8125rem 0.625rem;
  }
}

@media (min-width: 64rem) {
  .c-blocks-has-sidebar--left .c-block__content {
    padding-right: 2.5rem;
  }
  .c-blocks-has-sidebar--right .c-block__content {
    padding-left: 2.5rem;
  }
}

@media (min-width: 72rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 54.84375rem;
    width: 75%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-75 .c-block__inner, .c-block__content--w-75 {
    max-width: 57.1875rem;
  }
}

@media (min-width: 72rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 48.2625rem;
    width: 66%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block--w-66 .c-block__inner, .c-block__content--w-66 {
    max-width: 50.325rem;
  }
}

.c-block--w-50 {
  width: 50%;
  max-width: 50%;
}

@media (max-width: 64rem) {
  .c-block--w-50 {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 72rem) {
  .c-block__content--w-50 {
    max-width: 54.84375rem;
    width: 75%;
  }
}

@media (min-width: 80rem) {
  .c-block__content--w-50 {
    max-width: 36.5625rem;
    width: 50%;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__content--w-50 {
    max-width: 38.125rem;
  }
}

div[class*='c-block--h-fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-5'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-6'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-7'] {
    min-height: 25rem !important;
  }
}

div[class*='c-block--h-fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 85.4375rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 37.5rem !important;
  }
}

@media (max-width: 64rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  div[class*='c-block--h-fixed-8'] {
    min-height: 25rem !important;
  }
}

.owl-nav .icon {
  width: 0.9375rem;
  height: 1.5625rem;
  padding: 0;
}

.c-block__columns-arrows--dark .owl-nav .icon,
.c-block__slider-arrows--dark .owl-nav .icon {
  fill: #000;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  top: auto;
  transform: translateY(-50%);
}

.owl-nav .owl-prev {
  left: 0.9375rem;
}

.owl-nav .owl-prev:hover {
  left: 0.625rem;
}

.owl-nav .owl-next {
  right: 0.9375rem;
}

.owl-nav .owl-next:hover {
  right: 0.625rem;
}

.owl-dots .owl-dot {
  width: 0.6875rem;
  height: 0.6875rem;
  margin: 0 0.375rem;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.c-block__slider-dots--dark .owl-dots .owl-dot,
.c-block__gallery-dots--dark .owl-dots .owl-dot,
.c-block__columns-dots--dark .owl-dots .owl-dot {
  background-color: #000;
  border-color: black;
}

.owl-dots .owl-dot.active {
  width: 0.6875rem;
  height: 0.6875rem;
  background-color: rgba(0, 0, 0, 0.9);
  border-color: black;
}

.c-block__slider-dots--dark .owl-dots .owl-dot.active,
.c-block__gallery-dots--dark .owl-dots .owl-dot.active,
.c-block__columns-dots--dark .owl-dots .owl-dot.active {
  background-color: #fff;
  border: 0.0625rem solid black;
}

.c-block__slider {
  padding-bottom: 2.5rem;
}

.c-block__slider--full,
.c-block__slider--full .c-block__slider-slide {
  height: 100vh;
  min-height: 25rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 31.25rem;
  }
}

@media (min-width: 64rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 37.5rem;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__slider--full,
  .c-block__slider--full .c-block__slider-slide {
    min-height: 43.75rem;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-5'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-5'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-6'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-6'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-7'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-7'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-8'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-8'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-9'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-9'] {
    height: 25rem !important;
  }
}

.c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
    min-height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider div[class*='c-block__slider-slide--fixed-10'] {
    min-height: 25rem !important;
  }
}

.c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
  /* stylelint-disable-line selector-attribute-quotes */
}

@media (max-width: 64rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
    height: 31.25rem !important;
  }
}

@media (max-width: 48.0625rem) {
  .c-block__slider .item-video[class*='c-block__slider-slide--fixed-10'] {
    height: 25rem !important;
  }
}

.c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
  min-height: 25rem;
}

@media (min-width: 48.0625rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 31.25rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 28.125rem;
  }
}

@media (min-width: 64rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 37.5rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 30rem;
  }
}

@media (min-width: 85.4375rem) {
  .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 43.75rem;
  }
  .c-blocks--has-sidebar .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide,
  .c-page__content-shortcode .c-block__slider--auto:not(.c-block__slider--carousel) .c-block__slider-slide {
    min-height: 31.25rem;
  }
}

.c-block__slider-slide-caption-container {
  max-width: 73.125rem;
}

.c-block__slider--full .c-block__slider-slide-caption-inner {
  max-width: 71.5rem;
}

.c-block__slider-slide-caption--50 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

@media (min-width: 64rem) {
  .c-block__slider-slide-caption--50 {
    width: 50%;
  }
  .c-blocks--has-sidebar .c-block__slider-slide-caption--50 {
    width: 75%;
  }
}

.c-block__slider-slide-caption--75 {
  width: 100%;
}

@media (min-width: 48.0625rem) {
  .c-block__slider-slide-caption--75 {
    width: 75%;
  }
}

.c-block__slider .owl-dots {
  bottom: 0.3125rem;
}

.c-block__columns-column .c-book__cover {
  width: 35%;
  max-width: 10.625rem;
}

.c-block__columns-column-title {
  color: #000;
  margin-bottom: 1.875rem;
}

.c-block__columns-column-meta {
  font-size: 0.9375rem;
}

.c-block__columns-btn {
  width: 100%;
}

.home-columns .c-block__columns-column-inner {
  width: 12.1875rem;
  margin-left: auto;
  margin-right: auto;
}

.home-columns .c-block__columns-column-title {
  color: #fff;
  margin: 0;
  padding: 0.625rem 0.9375rem;
  background-color: #000;
}

.home-columns .c-block__columns-column-title a {
  color: inherit;
}

.home-columns .c-block__columns-column-title a:hover {
  color: #aaaaaa;
}

.home-columns .c-block__columns-column-image {
  border-top: 1px solid #fff;
}

@media (min-width: 64rem) {
  .home-columns .c-block__columns-column:nth-child(-n+4) {
    padding-top: 25px;
  }
  .home-columns .c-block__columns-column:nth-child(-n+4) .c-block__columns-column-image {
    position: relative;
    margin-bottom: 1.5625rem;
  }
}

@media (min-width: 64rem) and (max-width: 80rem) {
  .home-columns .c-block__columns-column:nth-child(-n+4) .c-block__columns-column-image {
    margin-bottom: 1.875rem;
  }
}

@media (min-width: 64rem) {
  .home-columns .c-block__columns-column:nth-child(-n+4) .c-block__columns-column-image:after {
    position: absolute;
    width: 100%;
    bottom: -1.5625rem;
    content: '';
    display: block;
    border-bottom: 2px solid #dadada;
  }
}

@media (max-width: 64rem) {
  .home-columns .c-block__columns-column {
    padding-top: 15px !important;
  }
  .home-columns .c-block__columns-column .c-block__columns-column-image {
    position: relative;
    margin-bottom: 1.5625rem;
  }
  .home-columns .c-block__columns-column .c-block__columns-column-image:after {
    position: absolute;
    width: 100%;
    bottom: -1.5625rem;
    content: '';
    display: block;
    border-bottom: 2px solid #dadada;
  }
}

@media (max-width: 64rem) and (min-width: 48.0625rem) {
  .home-columns .c-block__columns-column:nth-last-child(-n+2) .c-block__columns-column-image {
    margin-bottom: 0;
  }
  .home-columns .c-block__columns-column:nth-last-child(-n+2) .c-block__columns-column-image:after {
    display: none;
  }
}

@media (max-width: 48.0625rem) {
  .home-columns .c-block__columns-column:last-child .c-block__columns-column-image {
    margin-bottom: 0;
  }
  .home-columns .c-block__columns-column:last-child .c-block__columns-column-image:after {
    display: none;
  }
}

.home-columns .c-block__columns--4 .c-block__columns-column {
  position: relative;
}

@media (min-width: 64rem) {
  .home-columns .c-block__columns--4 .c-block__columns-column:nth-child(4n+1) {
    border-right: 2px solid #dadada;
  }
  .home-columns .c-block__columns--4 .c-block__columns-column:nth-child(4n+4) {
    border-left: 2px solid #dadada;
  }
}

@media (max-width: 64rem) {
  .home-columns .c-block__columns--4 .c-block__columns-column:nth-child(2n+1) {
    border-right: 2px solid #dadada;
  }
}

@media (max-width: 48.0625rem) {
  .home-columns .c-block__columns--4 .c-block__columns-column:nth-child(2n+1) {
    border-right: none;
  }
}

.c-block__tabs-nav li {
  border: 0.0625rem solid #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav li {
  border-color: #666666;
}

.c-block__tabs-nav .active a {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav .active a {
  background-color: #666666;
  color: #fff;
}

.c-block__tabs-nav a {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav a {
  background-color: #777777;
  color: #fff;
}

.c-block__tabs-nav a:hover {
  background-color: #eeeeee;
}

.c-block__tabs--dark .c-block__tabs-nav a:hover {
  background-color: #666666;
  color: #fff;
}

.c-block__tabs-content {
  background-color: #eeeeee;
  font-size: 0.875rem;
}

.c-block__tabs--dark .c-block__tabs-content {
  background-color: #666666;
  color: #fff;
}

.c-block__tabs-accordion-header a {
  border-bottom: 0.0625rem solid #000;
  background-color: #fff;
}

.c-block__tabs--dark .c-block__tabs-accordion-header a {
  background-color: #777777;
  color: #fff;
}

.c-block__tabs-accordion-header .c-arrow-icon {
  fill: #666666;
}

.c-block__tabs--dark .c-block__tabs-accordion-header .c-arrow-icon {
  fill: #eeeeee;
}

.c-block__tabs-accordion-header.active a {
  border-bottom: 1px solid #fff;
  background-color: #fff;
}

.c-block__tabs--dark .c-block__tabs-accordion-header.active a {
  background-color: #666666;
  color: #fff;
}

.c-block__tabs-accordion-content {
  background-color: #fafafa;
  font-size: 0.875rem;
}

.c-block__tabs--dark .c-block__tabs-accordion-content {
  background-color: #666666;
  color: #fff;
}

.c-block__testimonials {
  max-width: calc(100% - 70px);
}

.c-block__testimonials .owl-nav .owl-prev,
.c-block__testimonials .owl-nav .owl-next {
  background-color: #eeeeee;
}

.c-block__testimonials .owl-nav .owl-prev:hover,
.c-block__testimonials .owl-nav .owl-next:hover {
  background-color: #aaaaaa;
}

.c-block__testimonials .owl-nav .owl-prev.invisible, .c-block__testimonials .owl-nav .owl-prev.invisible:hover,
.c-block__testimonials .owl-nav .owl-next.invisible,
.c-block__testimonials .owl-nav .owl-next.invisible:hover {
  background-color: #eeeeee;
}

.c-block__testimonials-item {
  background-color: #eeeeee;
}

.c-block__testimonials-item .icon-quote {
  fill: #d6d6d6;
}

.c-block__testimonials-meta img {
  width: 3.75rem !important;
}

.c-block__testimonials-info p {
  font-size: 0.875rem;
}

.c-block__social-media-widget-instafeed a {
  background-color: #eeeeee;
}

.c-block__social-media-widget-instafeed span {
  color: #777777;
  font-size: 0.875rem;
}

.c-block__newsletters-list-item-inner {
  max-width: 25.625rem;
  border: 1px solid #eeeeee;
}

.c-instagram__data {
  background-color: rgba(0, 0, 0, 0.8);
}

.c-instagram__data-inner {
  max-width: 31.25rem;
  background-color: #fff;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2);
}

.c-block__contact-form .cform-container {
  left: -0.8125rem;
  width: calc(100% + 26px);
}

.c-block__call-to-action-content blockquote {
  padding: 0.625rem 1.25rem;
  margin: 1.25rem 0 1.5625rem;
  font-size: 0.875rem;
  border-left: 0.25rem solid #eeeeee;
  line-height: 2;
}

.c-page__hero {
  height: 25rem;
}

@media (max-width: 64rem) {
  .c-page__hero {
    height: 18.75rem;
  }
}

@media (max-width: 48.0625rem) {
  .c-page__hero {
    height: 15.625rem;
  }
}

.c-page__hero--container {
  max-width: 73.125rem;
}

.c-pagination {
  line-height: 1.875rem;
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  padding: 0;
  margin: 0.625rem 0;
}

@media (max-width: 72rem) {
  .c-pagination {
    line-height: 1.875rem;
  }
}

.c-pagination__link {
  margin: 0;
  color: #000;
  font-size: 0.875rem;
  border: 1px solid #777777;
}

@media (max-width: 72rem) {
  .c-pagination__link {
    font-size: 0.875rem;
  }
}

.c-pagination__link .icon {
  top: 0.25rem;
  width: 0.6rem;
  height: 1.2rem;
  fill: #000;
  transition: all 300ms ease-in-out;
}

.c-pagination__link--current, .c-pagination__link:hover {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.c-pagination__link--current .icon, .c-pagination__link:hover .icon {
  fill: #fff;
}

.page:not(.last-block-has-bg) main {
  padding-bottom: 1.875rem;
}

.c-page-content {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.c-project {
  border-bottom: 0.125rem solid #ddd;
}

@media (max-width: 48.0625rem) {
  .c-project {
    max-width: 250px !important;
  }
}

.c-project__title a:hover {
  color: #353a40;
}

.c-project__category {
  color: #dadada;
}

.c-project__lead a {
  color: #353a40;
}

.c-project__gallery-image p {
  border: 1px solid #ccc;
}

.search-results .c-breadcrumb {
  display: none;
}

.search-results h1 {
  display: block;
  margin-bottom: 1.875rem;
  font-size: 2.5rem;
  text-align: center;
}

@media (max-width: 64rem) {
  .search-results h1 {
    font-size: 2rem;
  }
}

@media (max-width: 48.0625rem) {
  .search-results h1 {
    font-size: 1.5rem;
  }
}

.search-results .c-search-list {
  display: block;
  max-width: 34.375rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-bottom: 2.5rem;
  list-style-type: none;
}

.search-results .c-search-list li hr {
  border: none;
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem;
  border-top: 1px solid #aaaaaa;
}

.search-results .c-search-list li a {
  color: #464646;
}

.search-results .c-search-list li a:hover {
  color: #00266b;
}

.c-section--primary {
  background-color: #262626;
}

.c-section--secondary {
  background-color: #000;
}

.select2-container .select2-selection--single {
  height: 2.625rem;
  border: 0.0625rem solid #666666;
}

@media (max-width: 64rem) {
  .select2-container .select2-selection--single {
    height: 2.375rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single {
    height: 2.125rem;
  }
}

.select2-container .select2-selection--single.select2--small {
  height: 2.25rem;
}

.select2-container .select2-selection--single.select2--xsmall {
  height: 2rem;
  border: 0.0625rem solid #666666;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.625rem;
  color: #000;
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 2.375rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1.5rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
  line-height: 2.25rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--small .select2-selection__rendered {
    line-height: 1.5rem;
  }
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
  font-size: 0.9375rem;
  line-height: 2rem;
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single.select2--xsmall .select2-selection__rendered {
    line-height: 1.375rem;
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 2.75rem;
}

@media (max-width: 64rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.5rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 2.375rem;
  }
}

.select2-container--default .select2-selection--single.select2--small .select2-selection__arrow {
  height: 2.375rem;
}

.select2-container--default .select2-selection--single.select2--xsmall .select2-selection__arrow {
  height: 2rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #000 transparent transparent transparent;
  border-width: 0.375rem 0.3125rem 0 0.3125rem;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #000 transparent;
  border-width: 0 0.3125rem 0.375rem 0.3125rem;
}

.select2-dropdown {
  background-color: #fff;
  border: 0.0625rem solid #666666;
}

.select2-dropdown.select2--xsmall {
  border-width: 0.0625rem;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 1rem;
}

@media (max-width: 48.0625rem) {
  .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 0.875rem;
  }
}

@media (max-width: 48.0625rem) {
  .select2-results__option {
    font-size: 0.9375rem;
  }
}

.select2-results__options.first-as-label .select2-results__option:first-child {
  background-color: #262626;
  color: #fff;
}

.select2--small .select2-results__option {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.select2--xsmall .select2-results__option {
  padding: 0.3125rem 0.625rem;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #eeeeee;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #777777;
  color: #000;
}

.c-sidebar__custom-content {
  max-width: 21.875rem;
}

.c-sidebar--4 .c-sidebar__custom-content {
  max-width: 25rem;
}

.c-sidebar__widget {
  max-width: 100%;
  margin-bottom: 1.25rem;
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
}

.c-sidebar__header {
  border-bottom: 0.125rem solid #353a40;
  color: #000;
  font-size: 1.125rem;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.widget_nav_menu ul li,
.widget_pages ul li,
.c-side-nav ul li,
.widget_recent_entries ul li,
.widget_rss ul li {
  /* stylelint-disable */
  /* stylelint-enable */
}

.widget_nav_menu ul li a,
.widget_pages ul li a,
.c-side-nav ul li a,
.widget_recent_entries ul li a,
.widget_rss ul li a {
  display: inline-block;
  padding: 0.3125rem 0;
  border: none;
  color: #00266b;
  transition: all 300ms ease-in-out;
  /* stylelint-disable selector-no-qualifying-type */
  /* stylelint-enable selector-no-qualifying-type */
}

.widget_nav_menu ul li a:hover, .widget_nav_menu ul li a.c-side-nav__link--current, .widget_nav_menu ul li a.c-side-nav__sublink--current,
.widget_pages ul li a:hover,
.widget_pages ul li a.c-side-nav__link--current,
.widget_pages ul li a.c-side-nav__sublink--current,
.c-side-nav ul li a:hover,
.c-side-nav ul li a.c-side-nav__link--current,
.c-side-nav ul li a.c-side-nav__sublink--current,
.widget_recent_entries ul li a:hover,
.widget_recent_entries ul li a.c-side-nav__link--current,
.widget_recent_entries ul li a.c-side-nav__sublink--current,
.widget_rss ul li a:hover,
.widget_rss ul li a.c-side-nav__link--current,
.widget_rss ul li a.c-side-nav__sublink--current {
  background-color: transparent;
  color: #000;
}

.widget_x5_widget_archives .widget_nav_menu ul li a:hover, .widget_x5_widget_archives .widget_nav_menu ul li a.c-side-nav__link--current, .widget_x5_widget_archives .widget_nav_menu ul li a.c-side-nav__sublink--current, .widget_x5_widget_archives
.widget_pages ul li a:hover, .widget_x5_widget_archives
.widget_pages ul li a.c-side-nav__link--current, .widget_x5_widget_archives
.widget_pages ul li a.c-side-nav__sublink--current, .widget_x5_widget_archives
.c-side-nav ul li a:hover, .widget_x5_widget_archives
.c-side-nav ul li a.c-side-nav__link--current, .widget_x5_widget_archives
.c-side-nav ul li a.c-side-nav__sublink--current, .widget_x5_widget_archives
.widget_recent_entries ul li a:hover, .widget_x5_widget_archives
.widget_recent_entries ul li a.c-side-nav__link--current, .widget_x5_widget_archives
.widget_recent_entries ul li a.c-side-nav__sublink--current, .widget_x5_widget_archives
.widget_rss ul li a:hover, .widget_x5_widget_archives
.widget_rss ul li a.c-side-nav__link--current, .widget_x5_widget_archives
.widget_rss ul li a.c-side-nav__sublink--current {
  background-color: #000;
}

.widget_nav_menu ul li.current-menu-item > a,
.widget_nav_menu ul li.current_page_item > a,
.widget_nav_menu ul li .c-side-nav__link--current > a,
.widget_nav_menu ul li .c-side-nav__link--current:hover > a,
.widget_pages ul li.current-menu-item > a,
.widget_pages ul li.current_page_item > a,
.widget_pages ul li .c-side-nav__link--current > a,
.widget_pages ul li .c-side-nav__link--current:hover > a,
.c-side-nav ul li.current-menu-item > a,
.c-side-nav ul li.current_page_item > a,
.c-side-nav ul li .c-side-nav__link--current > a,
.c-side-nav ul li .c-side-nav__link--current:hover > a,
.widget_recent_entries ul li.current-menu-item > a,
.widget_recent_entries ul li.current_page_item > a,
.widget_recent_entries ul li .c-side-nav__link--current > a,
.widget_recent_entries ul li .c-side-nav__link--current:hover > a,
.widget_rss ul li.current-menu-item > a,
.widget_rss ul li.current_page_item > a,
.widget_rss ul li .c-side-nav__link--current > a,
.widget_rss ul li .c-side-nav__link--current:hover > a {
  background-color: transparent;
  color: #000;
}

.widget_x5_widget_archives .widget_nav_menu ul li.current-menu-item > a, .widget_x5_widget_archives
.widget_nav_menu ul li.current_page_item > a, .widget_x5_widget_archives
.widget_nav_menu ul li .c-side-nav__link--current > a, .widget_x5_widget_archives
.widget_nav_menu ul li .c-side-nav__link--current:hover > a, .widget_x5_widget_archives
.widget_pages ul li.current-menu-item > a, .widget_x5_widget_archives
.widget_pages ul li.current_page_item > a, .widget_x5_widget_archives
.widget_pages ul li .c-side-nav__link--current > a, .widget_x5_widget_archives
.widget_pages ul li .c-side-nav__link--current:hover > a, .widget_x5_widget_archives
.c-side-nav ul li.current-menu-item > a, .widget_x5_widget_archives
.c-side-nav ul li.current_page_item > a, .widget_x5_widget_archives
.c-side-nav ul li .c-side-nav__link--current > a, .widget_x5_widget_archives
.c-side-nav ul li .c-side-nav__link--current:hover > a, .widget_x5_widget_archives
.widget_recent_entries ul li.current-menu-item > a, .widget_x5_widget_archives
.widget_recent_entries ul li.current_page_item > a, .widget_x5_widget_archives
.widget_recent_entries ul li .c-side-nav__link--current > a, .widget_x5_widget_archives
.widget_recent_entries ul li .c-side-nav__link--current:hover > a, .widget_x5_widget_archives
.widget_rss ul li.current-menu-item > a, .widget_x5_widget_archives
.widget_rss ul li.current_page_item > a, .widget_x5_widget_archives
.widget_rss ul li .c-side-nav__link--current > a, .widget_x5_widget_archives
.widget_rss ul li .c-side-nav__link--current:hover > a {
  background-color: #000;
}

.widget__content-box-inner {
  max-width: 21.875rem;
}

.c-sidebar--4 .widget__content-box-inner {
  max-width: 25rem;
}

@media (max-width: 64rem) {
  .widget__content-box-inner {
    max-width: 26.25rem;
  }
}

@media (max-width: 48.0625rem) {
  .widget__content-box-inner {
    max-width: 23.75rem;
  }
}

.widget_x5_widget_upcoming_events p {
  border: 0.0625rem solid #eeeeee;
}

.widget_x5_widget_upcoming_events a {
  color: #000;
  transition: all 300ms ease-in-out;
}

.widget_x5_widget_upcoming_events:hover a {
  background-color: #eeeeee;
}

.widget__newsletter {
  background-color: #000;
}

.widget__newsletter-link {
  color: #666666;
}

.widget__newsletter-link:hover .widget__newsletter-label {
  color: #666666;
}

.c-footer__sidebar .c-sidebar__widget {
  max-width: none;
  margin: auto;
}

@media (max-width: 64rem) {
  .c-footer__sidebar .c-sidebar__widget {
    max-width: 21.875rem;
  }
  .c-sidebar--4 .c-footer__sidebar .c-sidebar__widget {
    max-width: 25rem;
  }
}

.c-site__wrapper {
  max-width: 73.125rem;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0.3125rem 1.875rem 0.3125rem #b0b1b3;
  background-color: #fff;
}

.c-site__title {
  padding: 1rem 2.5rem 0.75rem;
  border-bottom: 0.125rem solid #ccc;
}

@media (max-width: 64rem) {
  .c-site__title {
    padding: 1rem 1.25rem 0.75rem;
  }
}

.c-site__title h2 {
  margin: 0;
  font-family: museo-sans, sans-serif;
  font-weight: bold;
}

.c-site__title h2 a {
  color: inherit;
}

.font-custom-block {
  padding-top: 0;
  padding-bottom: 0;
}

.font-custom-block * {
  font-family: museo-sans, sans-serif;
  font-weight: bold;
}

.font-custom-block a {
  color: #000;
}

.font-custom-block h1 {
  font-size: 2.5rem;
}

@media (max-width: 64rem) {
  .font-custom-block h1 {
    font-size: 2rem;
  }
}

@media (max-width: 48.0625rem) {
  .font-custom-block h1 {
    font-size: 1.5rem;
  }
}

.c-social-media {
  text-align: right;
}

.c-social-media__link {
  border: 0.125rem solid #000;
}

.c-social-media__link:hover {
  border-color: #353a40;
}

.c-social-media__link:hover .c-social-media__icon {
  fill: #353a40;
}

.u-fill {
  background: #fafafa;
}

body {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

p {
  font-size: inherit;
  line-height: 1.5rem;
}

p .u-text--enlarge {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

p .u-text--ensmall {
  font-size: 0.8125rem;
}

@media (max-width: 48.0625rem) {
  p .u-text--enlarge {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  p .u-text--ensmall {
    font-size: 0.8125rem;
  }
}

.u-text--light {
  font-weight: 300;
}
