.c-header {
  transition: padding 0.3s ease-in-out, border 0.3s ease-in-out;

  &--floating {
    padding: rem-calc(10 60);

    @include bp-custom($bp-nav-full-padding--default, 'max') {
      padding: rem-calc(10 10);
    }

    @include bp-large('max') {
      &-nomobile {
        padding: rem-calc(0);
      }
    }
  }

  &__container {
    padding: 0;
  }

  &--shrink {
    padding: 0;
  }

  &__inner {
    width: 100%;
    height: auto;
    padding: 0;
    border-bottom: rem_calc(4) solid $color-grey-light-medium;

    @include bp-large('max') {
      height: rem_calc(50);
      border-bottom: rem_calc(4) solid $color-grey-light-medium;
    }
  }

  &--shrink &__inner {
    height: auto;
  }

  &--container &__inner {
    max-width: $global-max-width;
  }

  &--floating &__inner {
    height: auto;
  }

  &--floating:not(&--full) &__container {
    max-width: $global-max-width;

    .body__fixed-header--floating & {
      max-width: none;
    }
  }

  &--floating#{&}--shrink &__container {
    max-width: none;
  }

  &--static#{&}--image {
    .c-header__inner {
      align-items: flex-end;
      height: rem_calc(120);

      @include bp-xxlarge('max') {
        height: rem_calc(100);
      }

      @include bp-large('max') {
        align-items: center;
        height: rem_calc(50);
      }
    }
  }
}

.c-header__logo {
  width: 25%;
  padding: rem_calc(0 10);

  a {
    height: 100%;
    width: 100%;
    max-width: rem_calc(300);
  }

  h1 {
    color: $color-white;
  }

  @include bp-xxlarge('max') {
    width: 20%;
  }

  @include bp-xlarge-medium('max') {
    width: 17%;
  }

  @include bp-large('max') {
    max-width: rem_calc(190);
    width: 100%;
  }
}

.c-header__nav {
  width: 75%;

  @include bp-xxlarge('max') {
    width: 80%;
  }

  @include bp-xlarge-medium('max') {
    width: 83%;
  }

  @include bp-large('max') {
    width: auto;
  }
}

.c-header__mobile-actions {
  .c-header--static.c-header--image & {
    margin-top: 0;
  }
}

.c-search {
  background-color: rgba($color-grey, 0.95);

  &__form {
    max-width: rem_calc(900);
    width: 95%;
    border: rem-calc(20) solid $color-theme-alt;

    @include bp-large('max') {
      border-width: rem-calc(10);
      max-width: rem_calc(500);
    }

    &:hover {
      border-color: $color-theme-alt !important;
    }
  }

  [type=text]#{&}__input {
    height: rem-calc(50);
  }

  &__btn {
    height: rem-calc(50);
  }

  &__close {
    color: $color-theme-alt;

    &:hover {
      color: $color-white !important;
      text-decoration: none !important;
    }
  }
}

.c-search__box {
  display: flex;
  margin-right: rem_calc(20);
  width: rem_calc(200);
  border: 1px solid $color-white;

  .c-mobile-nav & {
    margin: rem_calc(20) auto 0;
  }

  &-input {
    font-size: rem_calc(12) !important;
    height: rem_calc(25) !important;
    padding: rem-calc(7 10 5 10) !important;
    margin-bottom: 0 !important;
    border: none !important;
    background-color: transparent !important;
    color: $color-white !important;
    text-transform: uppercase;

    &::placeholder {
      color: $color-grey-light !important;
    }
  }

  &-btn {
    height: rem_calc(25);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  svg {
    width: 15px;
    height: 22px;
  }
}
