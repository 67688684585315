body {
  font-size: rem_calc(14);
  line-height: rem_calc(20);
}

p {
  font-size: inherit;
  line-height: rem_calc(24);

  .u-text--enlarge {
    font-size: rem_calc(18);
    line-height: rem_calc(28);
  }

  .u-text--ensmall {
    font-size: rem_calc(13);
  }

  @include bp-medium('max') {
    .u-text--enlarge {
      font-size: rem_calc(16);
      line-height: rem_calc(24);
    }

    .u-text--ensmall {
      font-size: rem_calc(13);
    }
  }
}

.u-text--light {
  font-weight: 300;
}
