.c-heading {
  border-bottom: none;
}

.c-heading--secondary {
  color: $color-black;
}

.c-heading--big {
  font-size: rem-calc(18);

  @include bp-medium {
    font-size: rem-calc(16);
  }
}

.c-page__title {
  padding-bottom: rem_calc(5);
  margin-bottom: rem_calc(5);
  margin-left: rem_calc(5);
  font-size: rem-calc(24);
  color: $color-black;

  .page-template-newsletter-list & {
    margin-bottom: rem_calc(15);
  }

  @include bp-large('max') {
    font-size: rem-calc(18);
  }

  // @include bp-medium {
  //   font-size: rem-calc(24);
  // }

  // @include bp-large {
  //   font-size: rem-calc(28);
  // }
}
