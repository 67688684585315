.c-member__inner {
  border-bottom: rem-calc(4) solid $color-grey-light-medium;
}

.c-member__title {
  background-color: $color-grey-light-medium;
  font-size: rem-calc(16);
}

.c-member__link {
  color: $color-black;

  &:hover {
    color: $color-black;
  }
}
