body {
  &.admin-bar { /* stylelint-disable-line selector-no-qualifying-type */
    padding-top: 32px !important;
  }
}

.body {
  &__fixed-header {
    padding-top: rem_calc(120);

    @include bp-large('max') {
      padding-top: rem_calc(100);
    }
  }

  &__fixed-header#{&}__floating-header {
    padding-top: 0;
  }
}
