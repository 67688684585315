.c-social-media {
  text-align: right;
}

.c-social-media__link {
  border: rem-calc(2) solid $color-black;

  &:hover {
    border-color: $color-theme-alt;

    .c-social-media__icon {
      fill: $color-theme-alt;
    }
  }
}
