.c-pagination {
  line-height: rem-calc(30);
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  padding: 0;
  margin: rem_calc(10 0);

  @include bp-xlarge('max') {
    line-height: rem-calc(30);
  }
}

.c-pagination__link {
  margin: 0;
  color: $color-black;
  font-size: rem_calc(14);
  border: 1px solid $color-grey;

  @include bp-xlarge('max') {
    font-size: rem_calc(14);
  }

  .icon {
    top: rem_calc(4);
    width: 0.6rem;
    height: 1.2rem;
    fill: $color-black;
    transition: $global-transition;
  }

  &--current,
  &:hover {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;

    .icon {
      fill: $color-white;
    }
  }
}
