.c-sidebar {
  &__custom-content {
    max-width: rem_calc($sidebar_max_width);

    .c-sidebar--4 & {
      max-width: rem_calc($sidebar_max_width_lg);
    }
  }
}

.c-sidebar__widget {
  max-width: 100%;
  margin-bottom: rem_calc(20);

  .c-footer__sidebar & {
    max-width: none;
  }
}

.c-sidebar__header {
  border-bottom: rem_calc(2) solid $color-theme-alt;
  color: $color-black;
  font-size: rem_calc(18);
  padding-top: 0;
  margin-top: 0;
  margin-bottom: rem_calc(10);
}

.widget_nav_menu,
.widget_pages,
.c-side-nav,
.widget_recent_entries,
.widget_rss {
  ul {
    li {
      a {
        display: inline-block;
        padding: rem_calc(5 0);
        border: none;
        color: $color-link-alt;
        transition: $global-transition;

        /* stylelint-disable selector-no-qualifying-type */

        &:hover,
        &.c-side-nav__link--current,
        &.c-side-nav__sublink--current {
          background-color: transparent;
          color: $color-link-alt-hover;

          .widget_x5_widget_archives & {
            background-color: $color-theme;
          }
        }

        /* stylelint-enable selector-no-qualifying-type */
      }

      /* stylelint-disable */

      &.current-menu-item > a,
      &.current_page_item > a,
      .c-side-nav__link--current > a,
      .c-side-nav__link--current:hover > a {
        background-color: transparent;
        color: $color-link-alt-hover;

        .widget_x5_widget_archives & {
          background-color: $color-theme;
        }
      }

      /* stylelint-enable */
    }
  }
}

.widget__content-box-inner {
  max-width: rem_calc($sidebar_max_width);

  .c-sidebar--4 & {
    max-width: rem_calc($sidebar_max_width_lg);
  }

  @include bp-large('max') {
    max-width: rem_calc(420);
  }

  @include bp-medium('max') {
    max-width: rem_calc(380);
  }
}

.widget_x5_widget_upcoming_events {
  p {
    border: rem_calc(1) solid $color-border;
  }

  a {
    color: $color-black;
    transition: $global-transition;
  }

  &:hover a {
    background-color: $color-border;
  }
}

.widget__newsletter {
  background-color: $color-theme;

  &-link {
    color: $color-grey-dark;

    &:hover .widget__newsletter-label {
      color: $color-grey-dark;
    }
  }
}

.c-footer__sidebar {
  .c-sidebar__widget {
    max-width: none;
    margin: auto;

    @include bp-large('max') {
      max-width: rem_calc($sidebar_max_width);

      .c-sidebar--4 & {
        max-width: rem_calc($sidebar_max_width_lg);
      }
    }
  }
}
